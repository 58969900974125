import {Component, Inject, OnDestroy} from '@angular/core';
import {ActivatedRoute, ActivationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {RoutingConfig} from './configs/routing-config';
import {AbstractRoleComponent} from './globals/screens/abstracts/abstract-role.component';
import {FileService} from './offline/api/services/file-service/file.service';
import {OfflineService} from './offline/services/offline-service/offline.service';
import {TagManagerService} from './services/tag-manager-service/tag-manager.service';
import {SubscriptionLoaded} from './subscriptions/services/interfaces/subscription-loaded';
import {SubscriptionAccessService} from './subscriptions/services/subscription-access.service';
import {BrowserUtil} from './utils/browser-util';
import {IdbVectorCacheUtil} from './utils/idb-cache-util';
import {OfflineUtil} from './utils/offline-util';
import {SecurityWorkerLogin} from './web-workers/service/interfaces/security-worker-login';
import {SecurityWorkerLogout} from './web-workers/service/interfaces/security-worker-logout';
import {SecurityWorkerSubscription} from './web-workers/service/interfaces/security-worker-subscription';
import {ServiceWorkerActivated} from './web-workers/service/interfaces/service-worker-activated';
import {ServiceWorkerError} from './web-workers/service/interfaces/service-worker-error';
import {ServiceWorkerUpdated} from './web-workers/service/interfaces/service-worker-updated';
import {ServiceWorkerCommunicationService} from './web-workers/service/service-worker-communication.service';
import {ServiceWorkerService} from './web-workers/service/service-worker.service';
import {Environment} from './runtime-environments/environment.interface';
import {APP_ENV} from './variables';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AbstractRoleComponent implements OnDestroy, ServiceWorkerActivated, ServiceWorkerUpdated,
  ServiceWorkerError, SecurityWorkerLogin, SecurityWorkerLogout, SecurityWorkerSubscription, SubscriptionLoaded {

  public title: string;
  public categoryId: string;
  public projectId: string;
  public versionId: string;
  public backLink: string;
  public ready: boolean;
  public showBack: boolean;
  public showSpinner = true;
  public showPrint: boolean;
  public showReload: boolean;
  public isReloading = false;
  public disableAccount: boolean;
  public showApprovals: boolean;

  private _login: boolean;
  private _actualUrl: string;
  private _noAccess: boolean;
  private _hasOfflineAccess: boolean;

  private _urlList: string[] = [];
  private _offlinePool: string[] = [];

  public constructor(private readonly _workerCommunication: ServiceWorkerCommunicationService, private readonly _route: ActivatedRoute,
                     private readonly _router: Router, private readonly _subscriptionAccess: SubscriptionAccessService,
                     private readonly _fileService: FileService, private readonly _translate: TranslateService,
                     private readonly _offline: OfflineService, @Inject(APP_ENV) private readonly _env: Environment,
                     deviceService: DeviceDetectorService, workerService: ServiceWorkerService, tagManagerService: TagManagerService) {
    super(workerService);
    this.setLanguage();
    if (this._env.env === 'prod') window.addEventListener('contextmenu', (event: any) => event.preventDefault());
    BrowserUtil.init(deviceService);
    this._router.events.subscribe((nav: ActivationEnd) => {
      if (!(nav instanceof ActivationEnd)) return;
      this.onRouting(nav.snapshot.params, nav.snapshot.routeConfig.path);
    });
    this._workerCommunication.addServiceWorkerActivatedListener(this);
    this._workerCommunication.addServiceWorkerUpdatedListener(this);
    this._workerCommunication.addServiceWorkerErrorListener(this);
    this._workerCommunication.addSecurityWorkerLoginListener(this);
    this._workerCommunication.addSecurityWorkerLogoutListener(this);
    this._workerCommunication.addSecurityWorkerSubscriptionListener(this);
    this._subscriptionAccess.addSubscriptionLoadedListener(this);
    workerService.register();
    tagManagerService.init();
  }

  private setLanguage(): void {
    this._translate.onLangChange.subscribe(() => {
      document.documentElement.setAttribute('lang', this._translate.instant('system.langCode'));
      this.setTitle();
    });
    this._translate.addLangs(['en', 'pl']);
    const language = this.getLanguage();
    this._translate.use(language);
    localStorage.setItem('language', language);
  }

  private getLanguage(): string {
    let language = localStorage.getItem('language');
    if (this._translate.getLangs().indexOf(language) >= 0) return language;
    language = this._translate.getBrowserLang();
    return this._translate.getLangs().indexOf(language) >= 0 ? language : this._env.language;
  }

  private checkStorage(): void {
    this._subscriptionAccess.clearAndReloadSubscriptionAccess();
  }

  private onRouting(params: any, url: string): void {
    this._noAccess = false;
    if (this.redirect(url)) return;
    this._actualUrl = url;
    this.title = undefined;
    setTimeout(() => {
      this.showBack = url !== '';
      this.setTitle();
      this.setMenuItems();
      this.onSubscriptionLoaded();
      this.setDisabledAccount();
      this.categoryId = params[RoutingConfig.CATEGORY_ID];
      this.projectId = params[RoutingConfig.PROJECT_ID];
      this.versionId = params[RoutingConfig.VERSION_ID];
      this._hasOfflineAccess = false;
      if (url !== RoutingConfig.PROJECT || this._login || !OfflineUtil.isOfflineAvailable()) return;
      this.showSpinner = true;
      this._offline.hasOfflineAccess(this.projectId).then((access: boolean) => {
        this._hasOfflineAccess = access;
        this.showSpinner = false;
      });
    }, 0);
  }

  private redirect(url: string): boolean {
    const redirect = this._login && ((url === RoutingConfig.ACCOUNT_MANAGE && !this.isUser && !this.isTeacherOrLower && !this.isInstitution) ||
      (url === RoutingConfig.ACCOUNT_SUBSCRIPTIONS && this.isCreatorOrHigher) ||
      (url === RoutingConfig.PROJECT_PREVIEW && this.isInstitutionOrLower));
    if (redirect) this._router.navigate(['/']);
    return redirect;
  }

  public onSecurityWorkerLogin(): void {
    this._login = true;
    this.showSpinner = false;
    this.redirect(this._actualUrl);
  }

  public onSecurityWorkerLogout(): void {
    this._login = false;
    this.showSpinner = false;
  }

  private setDisabledAccount(): void {
    this.disableAccount = this._actualUrl === RoutingConfig.CHANGE_PASSWORD || this._actualUrl === RoutingConfig.TEACHER_CONFIRM;
  }

  public onSubscriptionLoaded(): void {
    const classList = document.body.classList;
    classList.remove('full');
    if (this.showPrint && !this._subscriptionAccess.isDemo(this.projectId)) classList.add('full');
  }

  private setTitle(): void {
    switch (this._actualUrl) {
      case '':
        this.title = this._translate.instant('mainCategory.title');
        break;
      case RoutingConfig.ACCOUNT_PROFILE:
        this.title = this._translate.instant('profile.title');
        break;
      case RoutingConfig.ACCOUNT_SUBSCRIPTIONS:
        this.title = this._translate.instant('subscriptions.title');
        break;
      case RoutingConfig.ACCOUNT_MANAGE:
        this.title = this._translate.instant('assignmentManagement.title');
        break;
      case RoutingConfig.EMAIL_CONFIRM:
        this.title = this._translate.instant('emailConfirmation.title');
        break;
      case RoutingConfig.REGISTER_CONFIRM:
        this.title = this._translate.instant('registrationConfirmation.title');
        break;
      case RoutingConfig.CHANGE_PASSWORD:
        this.title = this._translate.instant('resetPassword.title');
        break;
      case RoutingConfig.TEACHER_CONFIRM:
        this.title = this._translate.instant('invitationConfirmation.title');
        break;
      case RoutingConfig.REGISTER:
        this.title = this._translate.instant('authorization.title.registration');
        break;
      case RoutingConfig.LOGIN:
        this.title = this._translate.instant('authorization.title.login');
        break;
      case RoutingConfig.RESET_PASSWORD:
        this.title = this._translate.instant('authorization.title.resetPassword');
        break;
      case RoutingConfig.ACCOUNT_OFFLINE_PROJECTS:
        this.title = this._translate.instant('offlineManagement.title');
        break;
      case RoutingConfig.MESSAGES:
      case RoutingConfig.MESSAGE:
        this.title = this._translate.instant('messages.title');
        break;
    }
  }

  public get canShow(): boolean {
    return this._login || (this._actualUrl === RoutingConfig.PROJECT && this._hasOfflineAccess) ||
      (this._actualUrl !== RoutingConfig.PROJECT && this._actualUrl !== RoutingConfig.PROJECT_PREVIEW &&
        this._actualUrl !== RoutingConfig.ACCOUNT_PROFILE && this._actualUrl !== RoutingConfig.ACCOUNT_SUBSCRIPTIONS &&
        this._actualUrl !== RoutingConfig.ACCOUNT_MANAGE && this._actualUrl !== RoutingConfig.MESSAGES &&
        this._actualUrl !== RoutingConfig.MESSAGE);
  }

  public get noAccess(): boolean {
    return this._actualUrl === RoutingConfig.PROJECT && this._login && this._noAccess;
  }

  public ngOnDestroy(): void {
    this._workerCommunication.removeServiceWorkerActivatedListener(this);
    this._workerCommunication.removeServiceWorkerUpdatedListener(this);
    this._workerCommunication.removeServiceWorkerErrorListener(this);
    this._workerCommunication.removeSecurityWorkerLoginListener(this);
    this._workerCommunication.removeSecurityWorkerLogoutListener(this);
    this._workerCommunication.removeSecurityWorkerSubscriptionListener(this);
    this._subscriptionAccess.removeSubscriptionLoadedListener(this);
  }

  public reloadProject(): void {
    if (!this.isProjectView()) return;
    this.isReloading = true;
    const id = this.versionId ? this.versionId : this.projectId;
    IdbVectorCacheUtil.revokeCacheForId(id);
    setTimeout(() => window.location.reload(), 100);
  }

  private setMenuItems(): void {
    const isProjectView = this.isProjectView();
    this.showPrint = isProjectView;
    this.showReload = isProjectView;
  }

  public onServiceWorkerActivated(): void {
    this.ready = true;
    this.checkStorage();
    if (this._env.env !== 'local') this.cacheViewer();
  }

  public onServiceWorkerError(error?: any): void {
  }

  public onServiceWorkerUpdated(): void {
    this.ready = false;
    this.checkStorage();
    if (this._env.env !== 'local') this.cacheViewer();
    setTimeout(() => this.ready = true, 0);
  }

  private cacheViewer(): void {
    fetch('is-viewer-cached').then((response: Response) => {
      if (response.status !== 404) return;
      this._fileService.listViewerFiles().subscribe((fileResponse: string[]) => {
        if (!fileResponse || fileResponse.length === 0) return;
        this._urlList = fileResponse;
        while (this._offlinePool.length < 5 && this._urlList.length > 0) {
          let url = this._urlList.shift();
          if (!url.startsWith('http')) {
            url = self.location.origin + url;
          }
          this._offlinePool.push(url);
          this.downloadFile(url);
        }
      });
    });
  }

  private downloadFile(url: string): void {
    fetch(url).then(() => {
      const index = this._offlinePool.indexOf(url);
      this._offlinePool.splice(index, 1);
      if (this._urlList.length <= 0) {
        if (this._offlinePool.length === 0) this.setViewerCached();
        return;
      }
      const newUrl = this._urlList.shift();
      this._offlinePool.push(newUrl);
      this.downloadFile(newUrl);
    });
  }

  public onSubscriptionNoAccess(): void {
    this._noAccess = true;
  }

  private isProjectView(): boolean {
    return this._actualUrl === RoutingConfig.PROJECT || this._actualUrl === RoutingConfig.PROJECT_PREVIEW;
  }

  private setViewerCached(): void {
    fetch('/set-viewer-cached');
  }
}
